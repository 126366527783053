import { Prediction, PredictionResult, predictions, Table, tables } from "./models";

export const getPrediction = (name: string) => {
  return predictions.find((prediction) => name === prediction.name);
}

export const getPredictionResults = (table: Table): PredictionResult[] => {
  return predictions.map((prediction) => ({name: prediction.name, score: calculateScore(prediction, table), accumulated: accululatedScore(prediction)}));
}

export const getSortedPredictions = (table: Table): PredictionResult[] => {
  const results = getPredictionResults(table);
  return results.sort((a, b) => b.score - a.score);
}

const accululatedScore = (prediction: Prediction): number => {
  return tables.map((table) => calculateScore(prediction, table)).reduce((p, c) => p+c, 0);
}

const calculateScore = (prediction: Prediction, table: Table): number => {
  let score = 0;
  if (prediction.fa === table.fa) {
    score += 2;
  }
  if (prediction.liga === table.liga) {
    score += 2;
  }
  if (table.topscorer.includes(prediction.topscorer)) {
    score += 2;
  }
  for (let i=0; i<table.table.length;i++) {
    if (table.table[i] === prediction.table[i]) {
      if((i === 0 || i === table.table.length -1)) {
        score += 2;
      } else {
        score += 1;
      }
    }
  }
  return score;
}


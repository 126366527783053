import Container from "@material-ui/core/Container";
import { PageProps } from "gatsby";
import * as React from "react"
import { Layout } from "../components/layout";
import { PLTable } from "../components/pl-table";
import { getPrediction } from "../libs/helpers";
import { PredictionResult } from "../libs/models";

interface PersonPageProps {
  prediction: PredictionResult
}

const PersonPage: React.FC<PageProps<null, null, PersonPageProps>> = ({location}) => {

  if (location?.state?.prediction) {
    const prediction = getPrediction(location.state.prediction.name);
  
    return (
      <Layout>
        <Container>
          <h1>{location.state.prediction.name}</h1>
          <PLTable withPrediction={prediction} />
        </Container>
      </Layout>
    )
  }
  return null;
}

export default PersonPage;
